<template>
  <div class="page-wrapper theme-green-forest">
    <Header />
    <PersonalPlanList />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import PersonalPlan from '@/components/views/home/PersonalPlan.vue'
import PersonalPlanList from '../components/views/home/PersonalPlanList.vue'
export default {
  components: { Header, PersonalPlan, PersonalPlanList, Footer },
  name: 'PersonalPlanAll',
  mounted() {
    if (window.innerWidth > 991) {
      this.$router.back()
    }
  },
}
</script>

<style></style>
