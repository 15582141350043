<template>
  <div class="wrapper-section">
    <section class="section">
      <div class="section__head">
        <div class="personal-plan__head">
          <button class="back-btn" @click="$router.back()">
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'arrow-left'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.333 16c.187 0 .38-.08.507-.234.24-.28.2-.7-.08-.94L1.7 8l8.067-6.827a.665.665 0 00.073-.94A.665.665 0 008.9.16L.233 7.493A.674.674 0 000 8c0 .193.087.38.233.506L8.9 15.84c.127.106.28.16.433.16z" fill="#fff"/></svg>
          </button>
          <h3 class="section__name">Your Personal Plan</h3>
        </div>
      </div>
      <div class="personal-plan" ref="plan">
        <div class="personal-plan__steps"></div>
        <div class="personal-plan__cards">
          <template v-for="i in filteredData" :key="i.day">
            <span class="personal-plan__day-title">Day {{ i.day }}</span>
            <template v-for="item in i.data" :key="item.id">
              <span v-if="item.isRest" class="personal-plan__rest-day"
                >Rest Day</span
              >
              <StepCard
                v-else
                class="step-card__wrapper"
                :data="item"
                :is-premium="true"
                :class="{ watched: item.watched }"
              />
            </template>
          </template>
          <span class="last-space"></span>
        </div>
      </div>
      <div class="previous-day">
        <button @click="onPrevDay" :disabled="disablePrevButton">
          Previous day
        </button>
      </div>
      <div class="loader" v-if="isLoading">
        <a-spin />
      </div>
    </section>
  </div>
</template>

<script>
import StepCard from '@/components/cards/StepCard.vue'
import { getPersonalPlanByDay } from '@/services/gist.service'
import { notification } from 'ant-design-vue'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'PersonalPlanList',
  components: { StepCard },
  data() {
    return {
      canScrollLeft: false,
      canScrollRight: false,
      content: [],
      contentAll: [],
      windowWidth: window.innerWidth,
      isLoading: false,
      currentPage: 1,
      lastPage: 1,
      currentDay: null,
    }
  },
  computed: {
    ...mapState(['isUserStatusLoading', 'userStatus']),
    filteredData() {
      return this.contentAll
    },
    disablePrevButton() {
      return this.currentDay - 1 === 0
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    onPrevDay() {
      this.currentDay--
      this.fetchData()
    },
    async fetchData() {
      try {
        this.isLoading = true
        const { data, current_day } = await getPersonalPlanByDay({
          day: this.currentDay,
        })
        this.currentDay = current_day
        if (!data.length) {
          this.content = [...this.content, { day: current_day, isRest: true }]
        } else this.content = [...this.content, ...data]
        const days = this.content
          .filter(
            (i, ind, arr) => arr.findIndex((j) => j.day === i.day) === ind
          )
          .map((i) => i.day)
        this.contentAll = []
        days.forEach((i) => {
          const d = {
            day: i,
            data: this.content.filter((j) => j.day === i),
            isRest: this.content
              .filter((j) => j.day === i)
              .every((i) => i.isRest),
          }
          this.contentAll.push(d)
        })
      } catch (err) {
        console.log(err)
        // notification.error({ message: err.message })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.step-card__wrapper {
  position: relative;
  &::before {
    content: url('~@/assets/icons/circle.svg');
    width: 26px;
    height: 26px;
    display: block;
    position: absolute;
    left: -40px;
    top: 50px;
  }
}

.step-card__wrapper.watched {
  &::before {
    content: '';
    width: 26px;
    height: 26px;
    display: block;
    background: url('~@/assets/icons/check-circle-24.svg');
    position: absolute;
    left: -40px;
    top: 50px;
  }
}

.step-card__wrapper + .step-card__wrapper {
  &::after {
    content: '';
    background-color: rgba(#fff, 0.2);
    width: 1px;
    height: 112px;
    display: block;
    position: absolute;
    left: -28px;
    bottom: 94px;
  }
}
.previous-day {
  button {
    border: none;
    background: rgba($color: #fff, $alpha: 0.1);
    border-radius: 4px;
    color: #fff;
    padding: 3px 10px;
    transition: all 100ms ease-in;
    margin-left: 125px;
    margin-top: 20px;

    &:hover {
      background: rgba($color: #fff, $alpha: 0.2);
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
.section {
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;
    padding-right: 36px;

    @media screen and (max-width: 991px) {
      padding-right: 24px;
    }
  }

  &__name {
    margin-bottom: 0;
    padding-left: 0;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }
}
.personal-plan {
  overflow: auto;
  scroll-behavior: smooth;
  display: flex;
  gap: 15px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__steps {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
  }

  &__step {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }

  &__cards {
    margin-top: 24px;
    display: grid;
    column-gap: 24px;
    grid-auto-columns: 288px;
    row-gap: 16px;
    grid-auto-flow: row;
    margin-top: 0;
    margin-left: 40px;
  }

  &__day {
    display: none;
  }

  &__rest-day {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    opacity: 0.5;
    color: #fff;
    margin-left: 120px;
    display: block;
    height: 40px;
  }

  &__day-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);
  }

  &__head {
    display: flex;
    align-items: center;
  }

  &__buttons {
    display: none;
  }

  &__button {
    background: rgba(#fff, 0.2);
    border-radius: 4px;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;

    &:hover {
      background: rgba(#fff, 0.3);
    }

    &:disabled {
      cursor: not-allowed;
      background: rgba(#fff, 0.1);
      path {
        fill: #98b7b4;
      }
    }
  }

  .last-space {
    width: 12px;
    background: inherit;
  }

  &__see-all {
    display: flex;
    align-items: center;

    &-btn {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-white);
      background: inherit;
      border: none;
      text-transform: capitalize;

      span {
        margin: 0 8px 0 3px;
      }
    }
  }
}

.step {
  &__line {
    background-color: rgba(#fff, 0.2);
    width: 1px;
    min-width: auto;
    min-height: 112px;

    &--before {
      min-width: 124px;
      width: 0px;
      min-width: auto;
      min-height: 52px;
    }
  }
  &__day {
    height: 37px;
  }

  &__rest {
    height: 86px;
  }

  &__after {
    height: 112px;
  }
}

.loader {
  margin: 24px;
}

.arrow-left {
  margin-right: 17px;
}

.back-btn {
  background: none;
  border: none;
  display: flex;
  align-items: center;
}
</style>
